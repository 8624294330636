import React, { useState, useCallback } from 'react'
import { debounce, TextField, Typography, Select, MenuItem } from '@mui/material'
import PointTable from './PointTable'
import moment from 'moment'

const DealerPointPanel = () => {
  const [searchFilter, setSearchFilter] = useState('')
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const startYear = 2024

  const [monthYearSelected, setMonthYearSelected] = useState(`${currentMonth}/${currentYear}`)

  const yearRange = []
  for (let year = currentYear; year >= startYear; year--) {
    const endMonth = year === currentYear ? currentMonth : 12
    for (let month = endMonth; month >= 1; month--) {
      yearRange.push({
        value: `${month}/${year}`,
        label: moment()
          .year(year)
          .month(month - 1)
          .add(1, 'months')
          .format('MMMM YYYY'),
      })
    }
  }

  const handleOnChange = useCallback(
    debounce((ev) => {
      const value = ev?.target?.value ?? ''
      setSearchFilter(value)
    }, 500),
    []
  )

  return (
    <>
      <Typography variant='h1' marginBottom={2}>
        Battery Lucky Draw Point
      </Typography>
      <TextField size='small' onChange={handleOnChange} variant='outlined' placeholder='Search ' />
      <Select
        sx={{ marginLeft: 2 }}
        value={monthYearSelected}
        onChange={(e) => setMonthYearSelected(e.target.value)}
        variant='outlined'
        size='small'
      >
        {yearRange.map((item, i) => (
          <MenuItem value={item.value} key={i}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <PointTable searchFilter={searchFilter} monthYearSelected={monthYearSelected} />
    </>
  )
}

export default DealerPointPanel
